<template>
  <div>
    <el-tabs v-model="activeTab" type="card" @tab-click="changeType">
      <el-tab-pane
        v-for="(tab, index) in tabs"
        :key="index"
        :name="tab.name"
        :label="tab.title"
        lazy
      >
        <component :is="`cycle-${tab.name}`" v-if="tab.name === activeTab" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CycleCalendar from './cycle-calendar/cycle-calendar.vue';
import CycleTable from './cycle-table/cycle-table.vue';

export default {
  components: { CycleCalendar, CycleTable },
  data() {
    return {
      activeTab: 'calendar',
      tabs: [
        { title: 'Календарь', name: 'calendar' },
        { title: 'Таблица', name: 'table' },
      ],
    };
  },
  methods: {
    changeType({ name }) {
      this.$router.replace({ query: { type: name } });
    },
  },
};
</script>
