import {
  FETCH_CYCLE_DATA,
  FETCH_CYCLE_EVENTS,
} from '@/app/administration/cycles/shared/state/types/action_types';
import { CYCLE_SET_TITLE } from '@/app/administration/cycles/shared/state/types/mutation_types';
import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import moment from 'moment';
import Vue from 'vue';

const routeData = Vue.observable({ params: {}, query: {}, meta: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
  routeData.meta = route.meta;
});

export const useCycleCalendar = () => {
  const {
    params: { id },
  } = routeData;

  const disabledView = ['years', 'year', 'week', 'day'];

  const cycle = computed(() =>
    !store.state.cyclesState.cycleData.selected ? {} : store.state.cyclesState.cycleData.selected
  );

  const setTitle = () => {
    const dateTo = moment(cycle.value.start).locale('ru').format('ll');
    const dateFrom = moment(cycle.value.end).locale('ru').format('ll');
    const title = `Цикл ${dateTo} - ${dateFrom}`;
    store.commit(CYCLE_SET_TITLE, title);
  };

  setTitle();

  const fetchCyclePositions = async () => {
    await store.dispatch(FETCH_CYCLE_EVENTS, {
      cycleId: id,
      params: { type: routeData.query.type },
    });
  };

  fetchCyclePositions();

  const positions = computed(() =>
    store.state.cyclesState.cycleData.cycleEvents
      ? store.state.cyclesState.cycleData.cycleEvents.calendar
      : []
  );

  const loading = computed(() => store.state.cyclesState.cycleData.loading);
  if (!Object.entries(cycle.value).length) {
    router.push({ name: 'Cycles' });
  }

  const fetchCycleData = async (date) => {
    await store.dispatch(FETCH_CYCLE_DATA, { cycleId: id, date });
  };

  const day = ref('');
  const week = ref(0);
  const date = ref('');

  const isShowModal = ref(false);

  const getDay = (date) => {
    const day = moment(date).locale('ru').format('dddd');
    return day[0].toUpperCase() + day.slice(1);
  };

  const getWeek = (date) => {
    const week = moment(date).week() - moment(cycle.value.start).week();
    return week === 0 ? 1 : week == 4 ? week : week + 1;
  };

  const openPositions = async (event) => {
    date.value = moment(event).format('YYYY-MM-DD');
    week.value = getWeek(event);
    day.value = getDay(event);
    await fetchCycleData(date.value);
    isShowModal.value = true;
  };

  return {
    positions,
    disabledView,
    cycle,
    loading,
    openPositions,
    day,
    week,
    date,
    isShowModal,
    fetchCyclePositions,
  };
};
