<template>
  <el-dialog
    :title="`${day} - Неделя ${week}`"
    :visible.sync="visible"
    width="60%"
    :before-close="closeModal"
  >
    <el-form ref="cycle" label-position="top" @submit.prevent.native="saveFn">
      <div v-if="isEdit" class="is-flex">
        <el-autocomplete
          v-model="guide.title"
          style="width: 90%"
          :fetch-suggestions="searchGuidesFn"
          :debounce="600"
          placeholder="Выберите блюдо"
          value-key="title"
          size="small"
          class="mr-4 outer-error"
          @select="selectGuideFn"
        >
          <template slot-scope="item">
            {{ item.item.title }} (<strong> {{ item.item.price }} ₽ </strong>)
            <small> - {{ item.item.meal }} </small>
            <i> - {{ item.item.branch }}</i>
          </template>
        </el-autocomplete>
        <el-button
          type="success"
          size="mini"
          :loading="loading"
          icon="el-icon-check"
          @click="saveFn"
        >
          Добавить
        </el-button>
      </div>
      <small v-if="selectError" class="has-text-danger">Укажите блюдо</small>
      <el-table :data="data ? data.data : []" style="width: 100%" class="mt-4">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-row :gutter="20">
              <el-col :span="12">
                <p>
                  Белки: <strong>{{ props.row.directory.proteins }}</strong> гр.
                </p>
                <p>
                  Жиры: <strong>{{ props.row.directory.fats }}</strong> гр.
                </p>
                <p>
                  Углеводы: <strong>{{ props.row.directory.carbohydrates }}</strong> гр.
                </p>
                <p>
                  Калории: <strong>{{ props.row.directory.calories }}</strong> гр.
                </p>
                <p>
                  Вес блюда: <strong>{{ props.row.directory.weight }}</strong> гр.
                </p>
                <p>
                  Цена: <strong>{{ props.row.directory.price }}</strong> руб.
                </p>
                <p>
                  Цена "по больше": <strong>{{ props.row.directory.price_custom }}</strong> руб.
                </p>
              </el-col>
              <el-col :span="12">
                <p>
                  Штрих код: <strong>{{ props.row.directory.dish.barcode }}</strong>
                </p>
                <p>
                  Цвет: <strong>{{ props.row.directory.dish.color }}</strong>
                </p>
                <p>
                  Время приготовления: <strong>{{ props.row.directory.dish.cooking_time }}</strong>
                </p>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="directory.dish.title" label="Название блюда" />
        <el-table-column label="Прием пищи">
          <template slot-scope="props">
            {{ props.row.directory.dish.meal ? props.row.directory.dish.meal.title : 'не указано' }}
          </template>
        </el-table-column>
        <el-table-column prop="directory.branch.title" label="Филиал">
          <template slot-scope="scope">
            {{ scope.row.directory.branch ? scope.row.directory.branch.title : 'не указан' }}
          </template>
        </el-table-column>
        <el-table-column prop="directory.price" label="Цена" />
        <el-table-column v-if="isEdit" width="80">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="deleteCycleDataFn(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeModal">Закрыть</el-button>
      <el-button v-if="isEdit" type="primary" :loading="loading" @click="closeModal">
        Сохранить
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  LOADING,
  CYCLE_DATA,
  ERROR,
} from '@/app/administration/cycles/shared/state/types/getter_types';
import { DATA as GUIDES_DATA } from '@/app/administration/guide/shared/state/types/getter_types';
import { FETCH_GUIDES } from '@/app/administration/guide/shared/state/types/action_types';
import {
  STORE_CYCLE_DATA,
  DELETE_CYCLE_DATA,
  CYCLE_DATA_RESET,
} from '@/app/administration/cycles/shared/state/types/action_types';
import { notifyMixin } from '@/app/administration/cycles/shared/mixins/notify-mixin';
import { userRole } from '@/app/shared/utils/roles';

export default {
  name: 'CycleDataModal',
  mixins: [notifyMixin],
  props: {
    day: {
      type: String,
      default: 'Инофрмация',
    },
    week: {
      type: Number,
      default: 0,
    },
    cycleId: {
      type: Number,
      default: 0,
    },
    date: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      guide: {
        title: null,
        id: null,
      },
      selectError: false,
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    ...mapGetters({ loading: LOADING, data: CYCLE_DATA, error: ERROR, guides: GUIDES_DATA }),
    ...mapState({
      options: (state) => state.cyclesState.cycleData.options,
    }),
    isEdit() {
      return this.options.permissions.edit.includes(userRole());
    },
  },
  methods: {
    ...mapActions({
      store: STORE_CYCLE_DATA,
      delete: DELETE_CYCLE_DATA,
      reset: CYCLE_DATA_RESET,
      fetchGuides: FETCH_GUIDES,
    }),
    closeModal() {
      this.visible = false;
      this.reset();
    },
    async saveFn() {
      if (!this.guide.title) {
        this.selectError = true;
        return;
      }
      const data = { date: this.date, cycle_id: this.cycleId, directory_dish_id: this.guide.id };
      await this.store(data);
      if (this.error) {
        this.showErrorMessage('Ошибка добавления');
      }
      this.showSuccessMessage('Успешно добавлен');
      this.guide = { title: null, id: null };
    },
    async searchGuidesFn(queryString, cb) {
      this.selectError = false;
      const filter = queryString ? { filter: { 'dish.title': queryString } } : {};
      await this.fetchGuides(filter);
      const data = this.guides.data.map((guide) => ({
        title: guide.dish.title,
        id: guide.id,
        meal: guide.dish.meal ? guide.dish.meal.title : 'не указано',
        branch: guide.branch ? guide.branch.title : 'не указано',
        price: guide.price,
      }));
      cb(data);
    },
    selectGuideFn(guide) {
      const { id, title } = guide;
      Object.assign(this.guide, { id, title });
    },
    async deleteCycleDataFn(row) {
      const { id, date } = row;
      await this.delete({ id, date });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-date-editor {
  width: 100% !important;
}
</style>
