<template>
  <div>
    <vue-cal
      v-loading="loading"
      style="height: 90vh"
      :time="false"
      locale="ru"
      active-view="month"
      :min-date="cycle.start"
      :max-date="cycle.end"
      :disable-views="disabledView"
      :events="positions"
      :events-on-month-view="true"
      @cell-dblclick="openPositions"
    />
    <cycle-data-modal
      :show.sync="isShowModal"
      :day="day"
      :week="week"
      :date="date"
      :cycle-id="+$route.params.id"
    />
  </div>
</template>
<script>
import VueCal from 'vue-cal';
import CycleDataModal from '../cycle-data-modal/cycle-data-modal.vue';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/ru';
import { useCycleCalendar } from './useCycleCalendar';

export default {
  components: { VueCal, CycleDataModal },
  setup() {
    const { positions, disabledView, cycle, loading, openPositions, day, week, date, isShowModal } =
      useCycleCalendar();

    return {
      positions,
      disabledView,
      cycle,
      loading,
      openPositions,
      day,
      week,
      date,
      isShowModal,
    };
  },
};
</script>
<style lang="scss">
.add-cycle {
  margin-bottom: 1rem;
}
.vuecal__cell:not(.vuecal__cell--disabled) {
  background-color: rgba(#409eff, 0.2);
}
.food-events {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  i {
    color: #f56c6c;
    margin-left: 5px;
  }
  .count-food {
    color: #606266;
  }
  .content-events {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}
.vuecal__event {
  background: transparent;
}
.vuecal__cell-events {
  height: 100%;
  position: absolute;
  background: transparent;
}
</style>
