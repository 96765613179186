<template>
  <div>
    <el-table v-loading="loading" :data="positions" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.cycleData">
            <el-table-column label="Название" prop="title" />
            <el-table-column label="Категория" prop="meal" />
            <el-table-column label="Цена" prop="price" />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="День недели" prop="dayName" />
      <el-table-column label="Количество блюд" prop="count" />
    </el-table>
  </div>
</template>
<script>
import { onMounted } from '@vue/composition-api';
import { useCycleTable } from './useCycleTable';

export default {
  setup() {
    const { positions, loading, fetchCyclePositions } = useCycleTable();

    onMounted(async () => {
      await fetchCyclePositions();
    });

    return {
      positions,
      loading,
    };
  },
};
</script>
