import {
  CYCLE_EVENTS_RESET,
  FETCH_CYCLE_EVENTS,
} from '@/app/administration/cycles/shared/state/types/action_types';
import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed } from '@vue/composition-api';
import Vue from 'vue';

const routeData = Vue.observable({ params: {}, query: {}, meta: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
  routeData.meta = route.meta;
});

export const useCycleTable = () => {
  const {
    params: { id },
  } = routeData;

  const fetchCyclePositions = async () => {
    await store.dispatch(FETCH_CYCLE_EVENTS, {
      cycleId: id,
      params: { type: routeData.query.type },
    });
  };

  const resetCyclePositions = () => {
    store.dispatch(CYCLE_EVENTS_RESET);
  };

  const positions = computed(() =>
    store.state.cyclesState.cycleData.cycleEvents
      ? store.state.cyclesState.cycleData.cycleEvents.table
      : []
  );

  const loading = computed(() => store.state.cyclesState.cycleData.loading);

  return {
    positions,
    loading,
    fetchCyclePositions,
    resetCyclePositions,
  };
};
